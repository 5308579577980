import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';

const API_URL = process.env.REACT_APP_SEQUENCE_API_URL || 'http://localhost:8080';
const API_TOKEN = process.env.REACT_APP_SEQUENCE_API_TOKEN

const root = createRoot(document.getElementById('root'));

const LoadingScreen = () => {
  const animationOrder = [2, 5, 8, 7, 6, 3, 0, 1];
  const animationDuration = 0.15;
  const totalDuration = animationOrder.length * animationDuration;

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      fontFamily: 'Helvetica Neue, Arial, sans-serif',
      color: '#1a1a1b',
      backgroundColor: '#ffffff'
    }}>
      <h1 style={{
        fontSize: '2rem',
        fontWeight: 700,
        letterSpacing: '0.2rem',
        textTransform: 'uppercase',
        marginBottom: '2rem',
        marginLeft: '2rem'
      }}>
        Sequencing...
      </h1>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '5px',
        width: '75px',
        height: '75px'
      }}>
        {[...Array(9)].map((_, index) => (
          <div key={index} style={{
            width: '25px',
            height: '25px',
            backgroundColor: index === 4 ? 'transparent' : '#d3d6da',
            animation: index !== 4 ? `fadeInOut ${totalDuration}s infinite ${animationOrder.indexOf(index) * animationDuration}s` : 'none'
          }} />
        ))}
      </div>
      <style>{`
        @keyframes fadeInOut {
          0%, 100% { background-color: #d3d6da; }
          ${12.5}%, ${25}% { background-color: #c8b653; }
        }
      `}</style>
    </div>
  );
};

async function fetchWithAuth(endpoint, method, body=null) {
  const headers = {
      'Authorization': `Bearer ${API_TOKEN}`,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': "*"
  }

  const options = {
      method: method,
      mode: 'cors',
      headers: headers
    };

  if (method !== 'GET' && body !== null) {
      options.body = JSON.stringify(body);
    }
    
  const response = await fetch(`${API_URL}${endpoint}`, options);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response;
}


function AppWrapper() {
  const [keys, setKeys] = useState(null);

  useEffect(() => {
    fetchWithAuth('/api/auth0_keys', 'GET')
    .then(response => response.json())
      .then(data => setKeys(data))
      .catch(error => console.error('Failed to fetch keys:', error));
  }, []);

  if (!keys) return <LoadingScreen />;

  return (
    <Auth0Provider
      domain={keys.REACT_APP_AUTH0_DOMAIN}
      clientId={keys.REACT_APP_AUTH0_CLIENT_ID}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      authorizationParams={{ redirect_uri: window.location.origin }}
    >
      <App />
    </Auth0Provider>
  );
}

root.render(<AppWrapper />);