import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import seedrandom from 'seedrandom';
import './SequenceGame.css';

const API_URL = process.env.REACT_APP_SEQUENCE_API_URL || 'http://localhost:8080';
const API_TOKEN = process.env.REACT_APP_SEQUENCE_API_TOKEN

async function fetchWithAuth(endpoint, method, body=null) {
    const headers = {
        'Authorization': `Bearer ${API_TOKEN}`,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': "*"
    }

    const options = {
        method: method,
        mode: 'cors',
        headers: headers
      };

    if (method !== 'GET' && body !== null) {
        options.body = JSON.stringify(body);
      }
      
    const response = await fetch(`${API_URL}${endpoint}`, options);
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  }

const SequenceGame = () => {
  const [copyButtonText, setCopyButtonText] = useState('Copy');
  const [countdownTime, setCountdownTime] = useState('');
  const [gameOver, setGameOver] = useState(false);
  const [hasPlayedToday, setHasPlayedToday] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isDisplayingSequence, setIsDisplayingSequence] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isProcessingClick, setIsProcessingClick] = useState(false);
  const [isRendering, setIsRendering] = useState(false);
  const [latestScore, setLatestScore] = useState(0);
  const [playerSequence, setPlayerSequence] = useState([]);
  const [score, setScore] = useState(0);
  const [sequence, setSequence] = useState([]);
  const [sequenceNumber, setSequenceNumber] = useState(0);
  const [showAboutModal, setShowAboutModal] = useState(true);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [showSharePrompt, setShowSharePrompt] = useState(false);
  const [speedMultiplier, setSpeedMultiplier] = useState(1);
  const [strikes, setStrikes] = useState(0);

  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

  useEffect(() => {
    const timer = setInterval(() => {
      const timeRemaining = calculateTimeRemaining();
      setCountdownTime(timeRemaining);

      if (timeRemaining === '00:00:00') {
        // Reset the game state for a new day
        setHasPlayedToday(false);
        setSequence([]);
        setPlayerSequence([]);
        setStrikes(0);
        setGameOver(false);
        setIsPlaying(false);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  
  useEffect(() => {
    // Check if the user has seen the About modal before
    const hasSeenAbout = localStorage.getItem('hasSeenAbout');
    if (hasSeenAbout) {
      setShowAboutModal(false);
    }
  }, []);


  const calculateTimeRemaining = () => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setUTCHours(24, 0, 0, 0);
    const difference = tomorrow - now;

    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleSpeedChange = (multiplier) => {
    setSpeedMultiplier(multiplier);
  };

  const handleCloseAboutModal = () => {
    setShowAboutModal(false);
    localStorage.setItem('hasSeenAbout', 'true');
  };

  const checkUserStatus = useCallback(async () => {
    if (!user) return;
    try {
      const body = {
        userId: user.sub,
        email: user.email,
        name: user.name
      }
      const response = await fetchWithAuth('/api/user', 'POST', body)
      const data = await response.json();
      setHasPlayedToday(data.hasPlayedToday);
      setSequenceNumber(data.currentSequence);
    } catch (error) {
      console.error('Error checking user status:', error);
    }
  }, [user]);

  useEffect(() => {
    if (isAuthenticated && user) {
      checkUserStatus();
    }
  }, [isAuthenticated, user, checkUserStatus]);

  const updateUserScore = useCallback(async (newScore) => {
    if (!user) return;
    try {
      const body = { score: newScore };
      const response = await fetchWithAuth(`/api/user/${user.sub}/score`, 'POST', body);
      const data = await response.json();
      setScore(data.score);
      setSequenceNumber(data.sequenceNumber);
      setHasPlayedToday(true);
    } catch (error) {
      console.error('Error updating user score:', error);
    }
  }, [user]);

  const fetchLatestScore = useCallback(async () => {
    if (!user) return;
    try {
      const response = await fetchWithAuth(`/api/user/${user.sub}`, 'GET')
      const data = await response.json();
      if (data.user && data.user.scores) {
        const today = new Date().toISOString().split('T')[0];
        const todayScore = data.user.scores[today];
        if (todayScore) {
          setLatestScore(todayScore.score);
        }
      }
    } catch (error) {
      console.error('Error fetching latest score:', error);
    }
  }, [user]);
  
  const generateDailySequence = useCallback(() => {
    const now = new Date();
    const utcDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    const seed = `${utcDate.getUTCFullYear()}-${utcDate.getUTCMonth() + 1}-${utcDate.getUTCDate()}`;
    const rng = seedrandom(seed);
  
    return Array.from({ length: 1000 }, () => Math.floor(rng() * 16));
  }, []);

  const highlightSquare = useCallback((square, color) => {
    const element = document.getElementById(`square-${square}`);
    element.style.backgroundColor = color;
    setTimeout(() => {
      element.style.backgroundColor = '';
    }, 300);
  }, []);

  const flashRed = useCallback((square) => {
    setIsProcessingClick(true);
    const element = document.getElementById(`square-${square}`);
    const flashTimes = 3;
    const flashInterval = 150;

    for (let i = 0; i < flashTimes * 2; i++) {
      setTimeout(() => {
        element.style.backgroundColor = i % 2 === 0 ? '#c94600' : '';
        if (i === flashTimes * 2 - 1) {
          setIsProcessingClick(false);
        }
      }, i * flashInterval);
    }
  }, []);

  const playSequence = useCallback((seq) => {
    setIsDisplayingSequence(true);
    seq.forEach((square, index) => {
      setTimeout(() => {
        highlightSquare(square, '#c8b653');
        if (index === seq.length - 1) {
          setTimeout(() => {
            setIsDisplayingSequence(false);
          }, 300 / speedMultiplier);
        }
      }, (index * 600) / speedMultiplier);
    });
  }, [highlightSquare, speedMultiplier]);

  const replaySequence = useCallback(() => {
    setPlayerSequence([]);
    playSequence(sequence);
  }, [sequence, playSequence]);

  const handleSquareClick = useCallback((square) => {
    if (!isPlaying || isDisplayingSequence || isProcessingClick || hasPlayedToday) return;

    setPlayerSequence(prevSequence => {
      const newSequence = [...prevSequence, square];
      const currentIndex = newSequence.length - 1;

      if (newSequence[currentIndex] !== sequence[currentIndex]) {
        flashRed(square);
        setStrikes(prevStrikes => {
          const newStrikes = prevStrikes + 1;
          if (newStrikes >= 3) {
            setIsPlaying(false);
            setGameOver(true);
            setShowSharePrompt(true);
            setHasPlayedToday(true);
            if (!isAnonymous) {
              updateUserScore(sequence.length - 1);
            }
            setLatestScore(sequence.length - 1);
          } else {
            // Replay the sequence on wrong guess
            setTimeout(replaySequence, 1000);
          }
          return newStrikes;
        });
        return [];
      } else {
        highlightSquare(square, '#6ca965'); // Green
        if (newSequence.length === sequence.length) {
          setIsDisplayingSequence(true);
          setTimeout(() => {
            setSequence(prevSequence => [...prevSequence, generateDailySequence()[prevSequence.length]]);
            playSequence([...sequence, generateDailySequence()[sequence.length]]);
          }, 1000);
          return [];
        }
        return newSequence;
      }
    });
  }, [isPlaying, isDisplayingSequence, isProcessingClick, hasPlayedToday, sequence, highlightSquare, flashRed, generateDailySequence, playSequence, replaySequence, isAnonymous, updateUserScore]);

  const startGame = useCallback(() => {
    if (!isAuthenticated && !isAnonymous) {
      setShowLoginPrompt(true);
      return;
    }
    if (hasPlayedToday && !isAnonymous) {
        setShowSharePrompt(true);
        return;
    }
    const dailySequence = generateDailySequence();
    setIsPlaying(true);
    setSequence([dailySequence[0]]);
    setPlayerSequence([]);
    setStrikes(0);
    setGameOver(false);
    playSequence([dailySequence[0]]);
  }, [isAuthenticated, isAnonymous, hasPlayedToday, generateDailySequence, playSequence]);

  useEffect(() => {
    if (isPlaying && sequence.length === 0) {
      startGame();
    }
  }, [isPlaying, sequence.length, startGame]);

  const formatDate = () => {
    const now = new Date();
    const utcDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
    // const utcDate = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    
    // Adjust for 8pm EDT reset (which is 00:00 UTC of the next day)
    utcDate.setUTCHours(utcDate.getUTCHours() + 4); // Adjust by 4 hours to match 8pm EDT reset
  
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
    return utcDate.toLocaleString('en-US', options);
  };

  const handleGameOver = useCallback(() => {
    setIsPlaying(false);
    setGameOver(true);
    if (!isAnonymous) {
        updateUserScore(sequence.length - 1);
      }
  }, [sequence.length, updateUserScore, isAnonymous]);

  const handleAnonymousPlay = () => {
    setIsAnonymous(true);
    setShowLoginPrompt(false);
  };

  const showResults = () => {
    if (!isAnonymous) {
      fetchLatestScore().then(() => setShowSharePrompt(true));
    }
    else {
      setScore(score);
      setSequenceNumber(sequenceNumber);
      setHasPlayedToday(true);
      setShowSharePrompt(true);
    }
  };

  const calculateSequenceNumber = () => {
    const startDate = new Date(2024, 6, 13); // Note: month is 0-indexed in JavaScript
    const today = new Date();
    const diffTime = Math.abs(today - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays;
  };

  const generateShareText = () => {
    const date = formatDate(new Date());
    const sequenceNumber = calculateSequenceNumber();
    return `${date}\nSequence #${sequenceNumber}\nScore: ${latestScore}\nhttps://sequence.games`;
  };

  const handleShare = () => {
    const shareText = generateShareText();
    navigator.clipboard.writeText(shareText).then(() => {
      setCopyButtonText('Copied!');
      setTimeout(() => {
        setCopyButtonText('Copy');
      }, 2000);
    }).catch(err => {
      console.error('Failed to copy: ', err);
      setCopyButtonText('Failed to copy');
      setTimeout(() => {
        setCopyButtonText('Copy');
      }, 2000);
    });
  };

  const handleModalClose = () => {
    setShowLoginPrompt(false);
    setShowSharePrompt(false);
  };

  return (
    <div className="game-container">
      <nav className="navbar">
        <button className="about-button" onClick={() => setShowAboutModal(true)}>About</button>
        <h1>Sequence</h1>
        {isAuthenticated ? (
          <button className="auth-button" onClick={() => logout({ returnTo: window.location.origin })}>Log Out</button>
        ) : (
          <button className="auth-button" onClick={() => loginWithRedirect()}>Log In</button>
        )}
      </nav>
      {showAboutModal && (
        <div className="modal" onClick={handleCloseAboutModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={handleCloseAboutModal}>&times;</span>
            {/* <h3>NOTE: If you previously created an account with Google, please recreate an account with your email :-)</h3> */}
            <h2>Sequence</h2>
            {/* <h4>Created by Sam Starkman</h4> */}
            {/* <p>Sequence is a memory game where you repeat an increasingly long pattern of colored squares.</p> */}
            <p>Step 1: Watch.</p>
            <p>Step 2: Repeat.</p>
            <p>Step 3: Share.</p>
            {/* <p>How to play:</p>
            <ul>
              <li>Watch the sequence of highlighted squares</li>
              <li>Repeat the sequence by clicking the squares in the same order</li>
              <li>The sequence gets longer with each successful round</li>
              <li>Make three mistakes, and the game is over</li>
            </ul>
            <p>Challenge yourself and see how long a sequence you can remember!</p> */}
          </div>
        </div>
      )}

      <p className="date">{formatDate()}</p>
      <div className="countdown-timer">
        (Next sequence in: {countdownTime})
      </div>
      <div className="game-info">
        <p>Level: {sequence.length}</p>
        <div className="speed-controls">
        <button 
          className={`speed-button ${speedMultiplier === 1 ? 'active' : ''}`} 
          onClick={() => handleSpeedChange(1)}
          aria-label="Slow speed"
        >
          <span className="speed-indicator">▶</span>
        </button>
        <button 
          className={`speed-button ${speedMultiplier === 1.5 ? 'active' : ''}`} 
          onClick={() => handleSpeedChange(1.5)}
          aria-label="Medium speed"
        >
          <span className="speed-indicator">▶▶</span>
        </button>
        </div>
        <p>Strikes: {strikes}/3</p>
      </div>
      <div className="grid">
        {[...Array(16)].map((_, i) => (
          <div
            key={i}
            id={`square-${i}`}
            className={`square ${isDisplayingSequence ? 'disabled' : ''}`}
            onClick={() => handleSquareClick(i)}
          />
        ))}
      </div>

      <div className={`start-game-container ${isPlaying ? 'hidden' : ''}`}>
      <button className="game-button" onClick={!hasPlayedToday ? startGame : showResults} disabled={isRendering}>
        {hasPlayedToday ? "See Results" : "Start Game"}
      </button>
      {isAnonymous && !hasPlayedToday && <p className="playing-anonymously">(TO PLAY AS GUEST, CLICK START GAME)</p>}
      {hasPlayedToday && !isPlaying && !gameOver && (
      <p className="already-played">You've already played today's sequence. Come back tomorrow!</p>
      )}
      </div>
      {showLoginPrompt && (
        <div className="modal" onClick={handleModalClose}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <span className="close" onClick={handleModalClose}>&times;</span>
            <p>Please log in to play the game.</p>
            <div className="button-container">
                <button onClick={() => loginWithRedirect()} className="modal-button login">Log In</button>
                <button onClick={handleAnonymousPlay} className="modal-button anonymous">Play As Guest</button>
            </div>
          </div>
        </div>
      )}
      {showSharePrompt && (
        <div className="modal" onClick={handleModalClose}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <span className="close" onClick={handleModalClose}>&times;</span>
            <p>Your results:</p>
            <pre>{generateShareText()}</pre>
            <button 
              onClick={handleShare}
              className={copyButtonText === 'Copied!' ? 'copied' : ''}
            >
              {copyButtonText}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SequenceGame;